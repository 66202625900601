import { type BaseEvent, ofType, type GenericEvent } from "./event";
import { Observable, from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";

export interface Epic<E extends GenericEvent> {
  send: (e: E) => void;
}

type HasPayload<T> = T extends { payload: infer P } ? P : undefined;

type EpicConfig<
  TInputEvent extends BaseEvent,
  TResult,
  TSuccessEvent extends BaseEvent,
  TFailureEvent extends BaseEvent,
> = {
  ofType: TInputEvent["type"];
  performTask: (payload: HasPayload<TInputEvent>) => Promise<TResult>;
  onSuccess: (payload: TResult) => TSuccessEvent;
  onFailure: (error: Error) => TFailureEvent;
};

export function createSimpleEpic<
  TInputEvent extends BaseEvent,
  TResult,
  TSuccessEvent extends BaseEvent,
  TFailureEvent extends BaseEvent,
>(
  config: EpicConfig<TInputEvent, TResult, TSuccessEvent, TFailureEvent>,
): (action$: Observable<BaseEvent>) => Observable<TSuccessEvent | TFailureEvent> {
  return (action$: Observable<BaseEvent>) => {
    return action$.pipe(
      ofType<
        BaseEvent,
        Extract<TInputEvent, { type: TInputEvent["type"] }>["type"],
        Extract<TInputEvent, { type: TInputEvent["type"] }>
      >(config.ofType),
      switchMap((action: Extract<TInputEvent, { type: TInputEvent["type"] }>) =>
        from(config.performTask("payload" in action ? (action as any).payload : undefined)).pipe(
          map((r) => config.onSuccess(r)),
          catchError((err) => {
            if (err instanceof Response) {
              return from(err.json()).pipe(
                map((text) => config.onFailure(new Error(text))),
                catchError(() => of(config.onFailure(new Error(`Error performing task: ${config.ofType}`)))),
              );
            }
            return of(config.onFailure(new Error(`Error performing task: ${config.ofType}`)));
          }),
        ),
      ),
    ) as Observable<TSuccessEvent | TFailureEvent>;
  };
}
